import axios from "axios";
// import {CardElement,useStripe,useElements} from '@stripe/react-stripe-js'

 //const API_URL = "https://divinests.com/admincms/api";
 //const IMAGES_URL = "https://divinests.com";

// const API_URL = "http://192.168.0.108:8000/api";
 //const IMAGES_URL = "http://192.168.0.108:8000";
//const API_URL = "http://127.0.0.1:8000/api";
//const IMAGES_URL = "http://127.0.0.1:8000";
const API_URL = "https://74.208.98.3/admincms/api"; 
 const IMAGES_URL = "https://74.208.98.3";
// const IMAGES_URL_edit = "http://74.208.98.3/admincms/";
const TOKEN_KEY = "token";


class Config {

  static async getPropertyDetails(propertyId) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/property/${propertyId}`, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  

  static async getHomeCardDetails(long,lat) {
    const token = "token";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      // console.log("long get home card details : ",long,"lat get home card details : ",lat);
      const response = await axios.get(`${API_URL}/properties?loc_id=&&name=&&category_id=0&&num_bedrooms=&&radius=200&&num_bathrooms=&&num_beds&&min_price=0&&max_price=1000&&limit=10&&offset=0&&lon=${long}&&lat=${lat}`,config);
      // console.log("get home card details",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async searchFunctionality(location, checkIn, checkOut, guests, minPrice, maxPrice, selectedCategory, maxRange, selectedPropertyType, selectedBedroom, selectedBeds, selectedBathroom,lon,lat) {
    const token = localStorage.getItem(TOKEN_KEY);
  

    const config = {
      // body,
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      if(selectedCategory == null){
        selectedCategory = 0;
      } 
      if(checkIn && checkIn !=""){

        const dateS = new Date(checkIn.$d);
        const yearS = dateS.getFullYear();
        const monthS = dateS.getMonth() + 1;
        const dayS = dateS.getDate();
        var checkinsend = `${yearS}-${monthS < 10 ? `0${monthS}` : monthS}-${dayS < 10 ? `0${dayS}` : dayS}`;

        const dateE = new Date(checkOut.$d);
        const yearE = dateE.getFullYear();
        const monthE = dateE.getMonth() + 1;
        const dayE = dateE.getDate();
        var checkoutsend = `${yearE}-${monthE < 10 ? `0${monthE}` : monthE}-${dayE < 10 ? `0${dayE}` : dayE}`;


      }else{
        var checkinsend = "";
        var checkoutsend = "";
      }

      // console.log("lon",lon,"lat",lat);

      // const response = await axios.get(`${API_URL}/properties?loc_id=&&name=&&category_id=${selectedCategory}&&num_bedrooms=${selectedBedroom}&&radius=${maxRange}&&num_bathrooms=${selectedBathroom}&&num_beds=${selectedBeds}&&limit=10&&offset=0&&lon=33.5&&lat=35.5&&guests=${guests}&&min_price=${minPrice}&&max_price=${maxPrice}&&available_starts_at=${checkIn}&&available_ends_at=${checkOut}&&type_id=${selectedPropertyType}`, config);
      const response = await axios.get(`${API_URL}/properties?loc_id=&&name=&&category_id=${selectedCategory}&&num_bedrooms=${selectedBedroom}&&radius=${maxRange}&&check_in=${checkinsend}&&check_out=${checkoutsend}&&num_bathrooms=${selectedBathroom}&&num_beds=${selectedBeds}&&limit=10&&offset=0&&lon=${lon}&&lat=${lat}&&guests=${guests}&&min_price=${minPrice}&&max_price=${maxPrice}&&available_starts_at=&&available_ends_at=&&type_id=${selectedPropertyType}`, config);
      // const response = await axios.get(`${API_URL}/properties?loc_id=&&name=&&category_id=0&&num_bedrooms=&&radius=200&&num_bathrooms=&&num_beds&&min_price=0&&max_price=1000&&limit=10&&offset=0&&lon=${lon}&&lat=${lat}`, {
      // });
      // console.log("response data",response.data);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async categoryFunctionality(category_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/categories/getProperties?category_id=${category_id}`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async makeReceipt(body) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/receipt`, body, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async makeReservation(body) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/user/reserve`, body, config);
      // console.log("response reserve : ",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async acceptReservation(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/accept-reservation`, body, config);
      // console.log("response reserve : ",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async rejectReservation(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/reject-reservation`, body, config);
      // console.log("response reserve : ",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async getUserDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/token/validate`, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateUserDetails(name, lastname, email, phoneNumber ,passport_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)

    const body = {
      "name": name,
      "last_name": lastname,
      "email": email,
      "phone_number": phoneNumber,
    
      "passport_id": passport_id,

    }
    try {
      const response = await axios.post(`${API_URL}/user/update`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating token:", error);
      throw error;
    }
  }

  static async getallchats(pages) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {

      const response = await axios.get(`${API_URL}/user/chat/getall?page=${pages}`, config);
      return response.data.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }

  static async getallmessages(user_id) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/user/chat/messages?user_id=${user_id}`, config);

      // Assuming "created_at" is a property in the API response, include it in the returned data
      const messagesWithData = response.data.data.map(message => ({
        ...message,
        created_at: message.created_at, // Adjust this based on the actual structure of the API response
      }));

      return messagesWithData;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }
  static async updateUnreadMessaged(user_id){
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const body = {
      "user_id": user_id,
    
    }
    try {
      const response = await axios.post(`${API_URL}/resetUnreadMessages`,body, config);
      
      // Assuming "created_at" is a property in the API response, include it in the returned data
      // const messagesWithData = response.data.data.map(message => ({
      //   ...message,
      //   created_at: message.created_at, // Adjust this based on the actual structure of the API response
      // }));

      // return messagesWithData;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }

  static async submitRate(property_id,rate){
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const body = {
      "property_id": property_id,
      "rate": rate,
    
    }
    console.log(body);
    try {
      const response = await axios.post(`${API_URL}/submitRate`,body, config);
      
     

      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }


  static async send_msg(message, user_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    console.log(message);
    console.log(user_id);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "user_id": user_id,
        "body": message
      }
      const response = await axios.post(`${API_URL}/message`, body, config);

      return response.data.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }

  static async updateUserImage(image) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(`${API_URL}/user/uploadImage`, image, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateUserPassword(oldPass, newPass) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      "old_password": oldPass,
      "password": newPass
    }
    try {
      const response = await axios.post(`${API_URL}/user/update-password`, body, config);
      return response.data;
    } catch (error) {
      console.log('Error validating token:', error);
      throw error;
    }
  }

  static async getUserAddress() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/user/address`, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateAddressDetails(country, state, city, street, zip) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)

    const body = {
      "country": country,
      "state": state,
      "city": city,
      "street": street,
      "zip": zip,
    }
    try {
      const response = await axios.post(`${API_URL}/user/address`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating token:", error);
      throw error;
    }
  }

  static async getOwnerProperties() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/properties`, config);
      // console.log(response);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getOwnerRequests() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/requests`, config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getOwnerPropertyRequest(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/requestsProperty`,body,config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }


  static async getOwnerAllRequestsThisProperty(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/allRequestsProperty`,body,config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }


  static async getRequestsPropertyFilter(body){

    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/filterRequestsProperty`,body,config);
      return response;

    } catch (error){
      console.error('Error validation tokem: ',error);
      throw error;
    }

  }

  static async getOwnerPropertiesFilter(propertyFilter){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "propertyName" : propertyFilter,
      }

      const response = await axios.post(`${API_URL}/owner/filterProperties`,body,config);
      return response;

    } catch (error){
      console.error('Error validation tokem: ',error);
      throw error;
    }
  }


  
  static async getOwnerPropertiesPagination(pagination,direction) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "pagination": pagination,
        "direction": direction==="left" ? "left" : "right",
      }
      
      const response = await axios.post(`${API_URL}/owner/propertiesPagination`, body, config);
      // console.log("response pagination : ",response.data);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getTransactionHistory() {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(`${API_URL}/user/reservation-history`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching transaction history:', error);
      throw error;
    }
  }

  static async getPaymentMethods() {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(`${API_URL}/user/payment-methods`, config);
      return response.data;
    } catch (error) {
      console.log("Error exception ",error);
      // console.error('Error fetching payment methods:', error);
      throw error;
    }
  }


  static async addCard(data){
    try {

      const token = localStorage.getItem(TOKEN_KEY);
      const config = {
        headers : {Authorization: `Bearer ${token}`},
      };

      const currentDate = new Date();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2,'0')}/${year}`;

      // const stripe = require('stripe')('your_stripe_secret_key');

      let body = {
        "type" : data.type,
        "number" : data.number,
        "expiry" : formattedDate,
        "cvc" : data.cvc,
        "name" : data.name,
      };

      // console.log(body);

      const response = await axios.post(`${API_URL}/user/add-card`,body,config);
      // console.log(response.data);
      return response.data;



    } catch (error){
      console.log("Error Exception", error);
      throw error;
    }
  }

  static async removeCard(cardId) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      "card_id": cardId
    }
    try {
      const response = await axios.post(`${API_URL}/user/remove-card`, body, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async addProperty(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      // console.log(formData.image);
      const response = await axios.post(`${API_URL}/owner/add-property`, formData, config);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }

  static async editProperty(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      //console.log(formData);
      const response = await axios.post(`${API_URL}/owner/update-property`, formData, config);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }

  static async uploadImgs(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      // console.log("form data image",formData);

      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1].name);
      }

      const response = await axios.post(`${API_URL}/owner/uploadImage`, formData, config);
      // const response = await axios.post(`${API_URL}/owner/uploadImageEdit`, formData, config);
      // console.log("response api : ",response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }


  static async uploadImgsEdit(formEdit){
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(`${API_URL}/owner/uploadImageEdit`, formEdit, config);
      // console.log(response.data);
      return response.data;
      
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }

  }

  static async getCategoriesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/categories`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getTypesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/getType`, config);
      return response.data;

    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getAmenities() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/amenitiesCategory`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getAmenitiesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/amenitiesCategory`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async requestOwnership(formData) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // const body = {
    //   'file' : formData.get('file'),
    //   'reason' : formData.get('reason'),
    // };



    try {
      // console.log("body",body);
      const response = await axios.post(`${API_URL}/user/request-ownership`,formData, config);
      // console.log("response api",response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async deleteProperty(property_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/property/remove`, { property_id }, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }
}

export { API_URL, IMAGES_URL };
export default Config;